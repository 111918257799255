import {
    patchState,
    signalStore,
    withMethods,
    withState,
  } from '@ngrx/signals';
  import { Partners, initialState } from './partners.model';
  
  export const PartnersStore = signalStore(
    withState(initialState),
  
    withMethods(({...store}) => ({
      setAll(partnersSignal: Partners["partnersSignal"]) {
        patchState(store, {partnersSignal});
      },
      setLoading(isLoading: boolean) {
        patchState(store, {loadingSignal: isLoading});
      },
      resetStore() {
        patchState(store, initialState);
      }
    }))
  );